"use client";
import { useEffect, useState } from "react";

const BackToTop = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleToggleVisible);
    return () => window.removeEventListener("scroll", handleToggleVisible);
  }, []);

  const handleToggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    scrolled > 800 ? setVisible(true) : setVisible(false);
  };

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <span onClick={scrollToTop} className={`back-to-top animate__animated ${visible ? "animate__fadeInUp" : "animate__zoomOutDown"}`} role="button" aria-label="Scroll to top">
      <i className="icofont-simple-up" />
    </span>
  );
};

export default BackToTop;
