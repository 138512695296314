import React from "react";
import { Spin } from "antd";
import useGlobalContext from "@/src/hooks/useGlobalContext";

const Loading = () => {
  const { loading } = useGlobalContext();
  return <Spin spinning={loading} fullscreen size="large" />;
};

export default Loading;
