"use client";
import { createContext, useState } from "react";
export const GlobalContext = createContext(null);

const GlobalContextProvider = ({ children }) => {
  const [token, setToken] = useState({});
  const [loading, setLoading] = useState(false);
  const [cldImageData, setCldImageData] = useState([]);

  return <GlobalContext.Provider value={{ token, setToken, loading, setLoading, cldImageData, setCldImageData }}>{children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;
