"use client";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import { Row, Col, Tooltip } from "antd";
import { WebRoutes } from "@/src/routes";
import { usePathname } from "next/navigation";
import BackToTop from "@/src/app/components/BackToTop";
import { COMPANY_DETAILS } from "@/src/utils/constant";

const Footer = () => {
  const pathname = usePathname();
  const footerClass = pathname === "/contact" || pathname === "/about" ? "mt-0!" : "";

  return (
    <footer id="footer" className={footerClass}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250" className="footerWave">
        <path fill="#02046b" fillOpacity="1" d="M0,224L80,213.3C160,203,250,181,480,181.3C640,181,800,203,960,197.3C1120,192,1280,160,1360,144L1440,128L1440,250L1360,250C1280,250,1120,250,960,250C800,250,640,250,480,250C250,250,160,250,80,250L0,250Z"></path>
      </svg>
      <div className="footer-top">
        <div className="container">
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <div className="footer-info text-center">
                <Image src={COMPANY_DETAILS.logo} alt="logo" width={220} height={68} />
                <p className="pb-4">Indo Aircon Engineers rates as a reputed contractor providing complete solutions for all sorts of Commercial and Domestic, Air Conditioning services for Hotels, Hospitals, Restaurants, Office Spaces, Institutes, Food & Beverage Industries & Industrial projects.</p>
                <p className="pb-4 text-center!">
                  <b>GST No. </b>
                  {COMPANY_DETAILS.gst_number}
                </p>
                <div className="social-links mt-4">
                  <Link href={COMPANY_DETAILS.socialMedia.whatsapp} target="_blank" rel="noopener noreferrer" aria-label="WhatsApp">
                    <i className="icofont-brand-whatsapp" />
                  </Link>
                  <Link href={COMPANY_DETAILS.socialMedia.facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                    <i className="icofont-facebook" />
                  </Link>
                  <Link href={COMPANY_DETAILS.socialMedia.instagram} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                    <i className="icofont-instagram" />
                  </Link>
                  <Link href={COMPANY_DETAILS.socialMedia.linkedin} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                    <i className="icofont-linkedin" />
                  </Link>
                  <Link href={COMPANY_DETAILS.socialMedia.twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                    <i className="icofont-twitter" />
                  </Link>
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={4} className="footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <Link href={WebRoutes.HOME}>Home</Link>
                </li>
                <li>
                  <Link href={WebRoutes.ABOUT}>About Us</Link>
                </li>
                <li>
                  <Link href={WebRoutes.SERVICES}>Services</Link>
                </li>
                <li>
                  <Link href={WebRoutes.CONTACT}>Contact Us</Link>
                </li>
              </ul>
            </Col>

            <Col xs={24} sm={24} md={12} lg={4} className="footer-links">
              <h4>Explore More</h4>
              <ul>
                <li>
                  <Link href={WebRoutes.GALLERY}>Gallery</Link>
                </li>
                <li>
                  <Link href={WebRoutes.SITEMAP}>Site Map</Link>
                </li>
                <li>
                  <Link href={WebRoutes.HOME}>Terms of service</Link>
                </li>
                <li>
                  <Link href={WebRoutes.HOME}>Privacy policy</Link>
                </li>
              </ul>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8} className="footer-links ContactUs">
              <h4>Contact Us</h4>
              <ul>
                <li>
                  {COMPANY_DETAILS.contact.phone
                    .map((phone) => (
                      <Link href={`tel:${phone.replace(/\s+/g, "")}`} key={phone}>
                        {phone}
                      </Link>
                    ))
                    .reduce((prev, curr) => [prev, ", ", curr])}
                </li>
                {COMPANY_DETAILS.contact.email.map((email) => (
                  <li key={email}>
                    <Link href={`mailto:${email}`}>{email}</Link>
                  </li>
                ))}
                <li>
                  <Link href={COMPANY_DETAILS.registered_address.googleMapLink} target="_blank" rel="noopener noreferrer">
                    <b className="block">Corporate Address:</b>
                    {COMPANY_DETAILS.corporate_address.street}, {COMPANY_DETAILS.corporate_address.city}, {COMPANY_DETAILS.corporate_address.state}-{COMPANY_DETAILS.corporate_address.postalCode}, {COMPANY_DETAILS.corporate_address.country}
                  </Link>
                </li>
                <li>
                  <Link href={COMPANY_DETAILS.registered_address.googleMapLink} target="_blank" rel="noopener noreferrer">
                    <b className="block">Registered Address:</b>
                    {COMPANY_DETAILS.registered_address.street}, {COMPANY_DETAILS.registered_address.city}, {COMPANY_DETAILS.registered_address.state}-{COMPANY_DETAILS.registered_address.postalCode}, {COMPANY_DETAILS.corporate_address.country}
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer-bg">
        <div className="copyright">
          <div className="container">
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} className="text-left">
                <span>Copyright &copy; {new Date().getFullYear()}</span>
                <strong> {COMPANY_DETAILS.name}. </strong>
                <span>All Rights Reserved.</span>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="text-right mt-4 md:mt-0">
                <Tooltip title={`by ${COMPANY_DETAILS.developer.username}`}>
                  <Link href={COMPANY_DETAILS.developer.github} target="_blank" rel="noopener noreferrer" className="text-white!">
                    Made with <i className="icofont-heart-alt text-[#eb3349] px-1" /> in India
                  </Link>
                </Tooltip>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <BackToTop />
    </footer>
  );
};

export default Footer;
