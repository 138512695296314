"use client";
import React, { useEffect, useState } from "react";
import Script from "next/script";
import Link from "next/link";
import Image from "next/image";
import { WebRoutes } from "@/src/routes";
import { COMPANY_DETAILS, SERVICES_OBJECT } from "@/src/utils/constant";
import Loading from "@/src/app/components/Loading";

const Header = () => {
  const [headerCollapse, setHeaderCollapse] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleHeaderScroll);
    return () => window.removeEventListener("scroll", handleHeaderScroll);
  }, []);

  const handleHeaderScroll = () => (document.documentElement.scrollTop > 50 ? setHeaderCollapse(true) : setHeaderCollapse(false));

  return (
    <header id="header" className={`flex items-center ${headerCollapse ? "header-scrolled" : ""}`}>
      <Loading />
      <div className="container flex items-center">
        <div className="logo mr-auto">
          <Link href={WebRoutes.HOME}>
            <Image src={COMPANY_DETAILS.logo} alt="logo" width={200} height={62} className="h-auto max-w-full" />
          </Link>
        </div>

        <nav className="nav-menu hidden lg:block" id="navbar">
          <ul id="ulNav">
            <li className="active">
              <Link href={WebRoutes.HOME}>Home</Link>
            </li>
            <li>
              <Link href={WebRoutes.ABOUT}>About Us</Link>
            </li>
            <li className="drop-down">
              <Link href={WebRoutes.SERVICES}>Services</Link>
              <ul>
                {Object.entries(SERVICES_OBJECT).map(([key, value]) => (
                  <li key={key}>
                    <Link className="scrollto" href={`${WebRoutes.SERVICES}#${key}`}>
                      {value}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link href={WebRoutes.GALLERY}>Gallery</Link>
            </li>
            <li>
              <Link href={WebRoutes.CONTACT}>Contact Us</Link>
            </li>
          </ul>
        </nav>
      </div>
      <Script
        id="mobile-nav-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
        (function() {
          if (document.querySelector(".mobile-nav") || document.querySelector(".mobile-nav-toggle")) return; 


          const navMenu = document.querySelector(".nav-menu");

          if (!navMenu) return;

          // Clone and append mobile nav
          const mobileNav = navMenu.cloneNode(true);
          mobileNav.classList.add("mobile-nav", "lg:hidden");
          mobileNav.classList.remove("hidden", "nav-menu");
          document.body.appendChild(mobileNav);

          // Create mobile nav toggle button
          const toggleButton = document.createElement("button");
          toggleButton.type = "button";
          toggleButton.className = "mobile-nav-toggle lg:hidden";
          toggleButton.ariaLabel = "Mobile Nav Toggle Button";
          toggleButton.innerHTML = '<i class="icofont-navigation-menu"></i>';
          document.body.prepend(toggleButton);

          // Create and append overlay
          const overlay = document.createElement("div");
          overlay.className = "mobile-nav-overly";
          document.body.appendChild(overlay);

          // Toggle mobile menu
          const handleToggle = () => {
            document.body.classList.toggle("mobile-nav-active");

            const icon = toggleButton.querySelector("i");
            if (icon) {
              icon.classList.toggle("icofont-navigation-menu");
              icon.classList.toggle("icofont-close");
            }
            overlay.style.display = overlay.style.display === "block" ? "none" : "block";
          };

          toggleButton.addEventListener("click", handleToggle);

          // Handle dropdowns in mobile nav
          document.querySelectorAll(".mobile-nav .drop-down > a").forEach((dropdown) => {
            dropdown.addEventListener("click", (e) => {
              e.preventDefault();
              const nextElement = dropdown.nextElementSibling;
              if (nextElement) {
                nextElement.style.display = nextElement.style.display === "block" ? "none" : "block";
              }
              dropdown.parentElement.classList.toggle("active");
            });
          });

          // Click outside to close menu
          const handleOutsideClick = (e) => {
            if (!mobileNav.contains(e.target) && !toggleButton.contains(e.target)) {
              if (document.body.classList.contains("mobile-nav-active")) {
                document.body.classList.remove("mobile-nav-active");

                const icon = toggleButton.querySelector("i");
                if (icon) {
                  icon.classList.add("icofont-navigation-menu");
                  icon.classList.remove("icofont-close");
                }
                overlay.style.display = "none";
              }
            }
          };

          document.addEventListener("click", handleOutsideClick);
        })();
      `,
        }}
      />
    </header>
  );
};

export default Header;
