"use client";
import { Analytics } from "@vercel/analytics/next";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { useEffect } from "react";
import Header from "@/src/app/(website)/header";
import Footer from "@/src/app/(website)/footer";
import Aos from "aos";
import "aos/dist/aos.css";
import "@/src/app/(website)/globals.scss";

export default function WebsiteLayout({ children }) {
  useEffect(() => {
    Aos.init({
      duration: 900,
      easing: "ease-in-out",
      once: true,
      mirror: false,
      disable: window?.innerWidth < 768,
    });
  }, []);

  return (
    <>
      <Header />
      {children}
      <Analytics />
      <SpeedInsights />
      <Footer />
    </>
  );
}
