export const WebRoutes = {
  HOME: "/",
  ABOUT: "/about",
  SERVICES: "/services",
  GALLERY: "/gallery",
  CONTACT: "/contact",
  SITEMAP: "/sitemap",
};

export const AdminRoutes = {
  ADMIN_LOGIN: "/admin",
  ANALYTICS_DASHBOARD: "/admin/analytics-dashboard",
  CONTACT_US_FORM: "/admin/contact-us-form",
  CLIENTS: "/admin/clients",
  SERVICES: "/admin/services",
  BILL_GENERATOR: "/admin/bill-generator",
  BILL_GENERATOR_GENERATE: "/admin/bill-generator/generate",
};
